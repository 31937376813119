<template>
  <ModalContainer :is-show-modal="isShowModal" @close="closeModal">
    <template #header>
      <div>{{ $t(modalText) }}</div>
      <IconButton icon="cross" @click="closeModal" />
    </template>

    <SelectTab
      v-if="isSelectTabVisible"
      :options="cooperationOptions"
      :value="currentCooperationType"
      @select="selectCooperationType"
    >
      <template #option="slotProps">
        {{ $t(slotProps.option.value) }}
      </template>
    </SelectTab>

    <form class="modal-form" @submit.prevent="onSubmit">
      <div class="modal-form-body">
        <BaseInput
          v-model="state.name"
          :placeholder="$t('Name')"
          :inner-placeholder="$t('Your name')"
          :error="errors?.name?.[0]"
        />
        <BaseInput
          v-model="state.phone"
          label="phone"
          :placeholder="$t('Phone')"
          :inner-placeholder="phoneNumberFormat[countryCode].placeholder"
          :error="errors?.phone?.[0]"
          :validator="validators.phone(countryCode)"
          @is-error="isInputError"
          type="tel"
          :help-text="$t('Number format: +375 00 000 00 00')"
          :trim="true"
        />
        <BaseInput
          v-model="state.email"
          label="email"
          :placeholder="$t('Email')"
          :inner-placeholder="emailPlaceholder"
          :error="errors?.email?.[0]"
          :validator="validators.email"
          @is-error="isInputError"
        />
        <BaseInput
          v-model="state.companyName"
          :placeholder="$t('Facility name')"
          :inner-placeholder="$t('Object name')"
          :error="errors?.companyName?.[0]"
        />
        <AgreementCheckbox v-model="state.processPersonalData" />
      </div>
      <div class="modal-form-control">
        <BaseButton
          type="submit"
          :loading="loading"
          :disabled="isDisabledButton"
        >
          {{ $t("Send") }}
        </BaseButton>
      </div>
    </form>

    <div class="modal-body__separator">
      <hr />
      <span class="text-18-500">{{ $t("or") }}</span>
      <hr />
    </div>

    <a class="modal-body__phone" :href="`tel:${officeContact?.phone}`" @click="reportAboutContact">
      <BaseIcon icon="phone" />
      <span class="text-18-500">{{ officeContact?.phone }}</span>
    </a>
  </ModalContainer>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { PropType } from "vue";

import { useConfigStore } from "~/stores/config";
import useInputValidation from "~/composables/useInputValidation";
import useWindowResize from "~/composables/useWindowResize";
import { Brand, CooperationTypes, SuccessModalTypes } from "~/types/entities";
import { phoneNumberFormat } from "~/utils/validators/phone";
import ModalContainer from "~/components/modals/ModalContainer.vue";
import BaseInput from "~/components/input/BaseInput.vue";
import SelectTab from "~/components/input/SelectTab.vue";
import validators from "~/utils/validators";
import AgreementCheckbox from "~/components/agreementCheckbox/AgreementCheckbox.vue";
import BaseButton from "~/components/button/BaseButton.vue";
import {
  COOPERATION_OPTIONS_DEFAULT,
  COOPERATION_OPTIONS_TABLET,
  REQUESTS,
} from "~/components/sections/GetDetailsSection.vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";
import IconButton from "~/components/button/IconButton.vue";

import { successModalKey } from "~/symbols";
import { useContentStore } from "~/stores/content";

import isEqual from "lodash/isEqual";
import { hidePD } from "~/utils/analytics";

const MODAL_TEXT = {
  [CooperationTypes.PARTNER]: "Become a Partner",
  [CooperationTypes.CONNECT_COMPANY]: "Get an Offer",
};

const emit = defineEmits(["update:isShowModal"]);

const props = defineProps({
  isShowModal: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  cooperationType: {
    type: String as PropType<CooperationTypes | null>,
    default: null,
  },
});

const { gtag } = useGtag();
const contentStore = useContentStore();
const { getContacts: officeContact } = storeToRefs(contentStore);

const { brand } = useRuntimeConfig().public;

const { isMobileScreen } = useWindowResize();
const { countryCode } = storeToRefs(useConfigStore());
const cooperationOptions = computed(() =>
  isMobileScreen.value
    ? COOPERATION_OPTIONS_DEFAULT
    : COOPERATION_OPTIONS_TABLET
);
const isSelectTabVisible = computed(() => !props.cooperationType);
const cooperationType = ref(
  props.cooperationType || CooperationTypes.CONNECT_COMPANY
);
const currentCooperationType = computed(
  () => props.cooperationType || cooperationType.value
);
const modalText = computed(() => MODAL_TEXT[currentCooperationType.value]);

const defaultState = {
  companyName: "",
  name: "",
  email: "",
  phone: "",
  location: "default", // Remove this when backend will be changed to not recieve the location
  processPersonalData: false,
  country: countryCode.value,
};
const state = reactive({ ...defaultState });

const loading = ref(false);
const errors = ref<any>(null);

const gEventPayload = computed(() => isEqual(state, defaultState) ? undefined : hidePD(state));
watch(
  () => state,
  () => {
    if (errors.value) {
      errors.value = null;
    }
  },
  { deep: true }
);
const { isValid, isInputError } = useInputValidation(state);
const isDisabledButton = computed(() => !isValid.value || !!errors.value);

const preparedPhone = computed(() => removePlusSymbol(state.phone));

const showSuccessModal = inject(successModalKey, () => {});

function closeModal() {
  gtag("event", GetOfferModalEvents.close, gEventPayload.value);
  resetState();
  emit("update:isShowModal", false);
}

function resetState() {
  Object.assign(state, defaultState);
}

async function onSubmit() {
  gtag("event", GetOfferModalEvents.submit, gEventPayload.value);

  loading.value = true;
  try {
    await REQUESTS[currentCooperationType.value]({
      ...state,
      phone: preparedPhone.value,
    });
    loading.value = false;
    closeModal();

    if (CooperationTypes.CONNECT_COMPANY === currentCooperationType.value) {
      showSuccessModal(SuccessModalTypes.CONNECT_COMPANY);
    } else {
      showSuccessModal(SuccessModalTypes.PARTNER);
    }
  } catch (e: any) {
    if (e && e.data && e.data.errors) {
      errors.value = e.data.errors;
    }
    loading.value = false;
  }
}

const emailPlaceholder = computed(() =>
  brand === Brand.sportbenefit
    ? "qwerty@sportbenefit.eu"
    : "qwerty@allsports.by"
);

function selectCooperationType(optionId: CooperationTypes) {
  gtag("event", GetOfferModalEvents[optionId], gEventPayload.value);
  cooperationType.value = optionId;
}

const reportAboutContact = () =>  gtag("event", GetOfferModalEvents.support, gEventPayload.value);
</script>
